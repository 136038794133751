import Cookies from 'js-cookie';

type Consent = 'full' | 'min';

export function getCookieConsent(): Consent | undefined {
  const consent = Cookies.get('cookie-consent');
  if (consent === 'full' || consent === 'min') {
    return consent;
  }
  return undefined;
}

export function setCookieConsent(consent: Consent) {
  Cookies.set('cookie-consent', consent, {
    domain: getTopDomain(),
    expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000),
  });
}

function getTopDomain() {
  const domain = window.location.hostname.split('.').slice(-2).join('.');
  return domain;
}
