import posthog from 'posthog-js';

import { getCookieConsent } from './cookie';

export { PostHogProvider, useFeatureFlagEnabled } from 'posthog-js/react';

export function initPosthog(params: { key?: string; host?: string; flags?: Record<string, any> }) {
  const consent = getCookieConsent();
  if (params.key === undefined || params.host === undefined) {
    console.info('Posthog key or host is not defined, tracking disabled.');
    return;
  }
  posthog.init(params.key, {
    api_host: params.host,
    persistence: consent ? 'localStorage+cookie' : 'memory',
    opt_out_capturing_by_default: Boolean(localStorage.getItem('cacheBustToken')),
    bootstrap: {
      featureFlags: params.flags,
    },
  });
  return posthog;
}

export function optInTracking() {
  posthog.set_config({ disable_persistence: false, persistence: 'localStorage+cookie' });
  posthog.opt_in_capturing();
  posthog.persistence?.save();
}

export function resetTracking() {
  posthog.reset();
}

export const tracker = posthog;
